import styled from "styled-components";

export const CtaContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  margin: 95px auto;
  height: 80vh;

  @media (min-width: 768px) {
    height: calc(100vh - 116px);
  }
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem;
    padding: 0 5rem;
  }
`;

export const CtaTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  @media (min-width: 768px) {
    align-items: flex-start;
  }
`;

export const CtaAboveTitle = styled.p`
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const CtaTitle = styled.h1`
  font-size: clamp(2.5rem, 5vw, 4rem);
  line-height: clamp(3rem, 7vw, 6rem);
  text-align: center;
  margin-bottom: 1.7rem;

  @media (min-width: 768px) {
    max-width: 80%;
    text-align: left;
  }
`;

export const CtaImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  margin: 1rem;

  @media (min-width: 768px) {
    max-width: 80%;
  }
`;

export const CtaImage = styled.img`
  max-width: 100%;
`;
