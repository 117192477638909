import React, { useState, useEffect } from "react";
import {
  Nav,
  Logo,
  MobileIcon,
  NavbarContainer,
  NavbarElements,
  NavbarElementsContainer,
  NavbarElementsWrapper,
  NavLinks,
  LogoImgSvg,
} from "./LayoutNavElements";
import { FaBars, FaTimes } from "react-icons/fa";
import { ThemeProvider } from "styled-components";
import { useDarkMode } from "../useDarkMode";
import { lightTheme, darkTheme } from "../styling/theme";
import Toggle from "../Toggle/Toggle";

const Layout = ({ children }) => {
  const [click, setClick] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);
  const [theme, toggleTheme, componentMounted] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  if (!componentMounted) {
    return <div />;
  }

  function handleClick() {
    setClick(!click);
  }

  return (
    <ThemeProvider theme={themeMode}>
      <Nav scrollNav={scrollNav}>
        <Logo>
          <LogoImgSvg alt="image of logo" />
        </Logo>

        <NavbarContainer>
          <MobileIcon onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </MobileIcon>
          <NavbarElementsContainer>
            <Toggle theme={theme} toggleTheme={toggleTheme} />
            <NavbarElements onClick={handleClick} click={click}>
              <NavbarElementsWrapper>
                <NavLinks
                  onClick={handleClick}
                  to="home"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  aria-label="home"
                >
                  Home
                </NavLinks>
                <NavLinks
                  onClick={handleClick}
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  aria-label="about me"
                >
                  About
                </NavLinks>                
                <NavLinks
                  onClick={handleClick}
                  to="hobbies"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  aria-label="hobbies"
                >
                  Interests
                </NavLinks>               
              </NavbarElementsWrapper>
            </NavbarElements>
          </NavbarElementsContainer>
        </NavbarContainer>
      </Nav>
      {children}
    </ThemeProvider>
  );
};

export default Layout;
