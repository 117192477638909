export const lightTheme = {
  body: "#fafafa",
  text: "#010101;",
  button: "#808AFF",
  button_text: "#1d1d1d",
  hover: "#4b59f7",
  hyperlink: "#4b59f7",
  visited_hyperlink: "#e89e43",
  h1: "6rem",
  h2: "4rem",
  h3: "2rem",
};

export const darkTheme = {
  body: "#363537",
  text: "#FAFAFA",
  button: "#808AFF",
  button_text: "#1d1d1d",
  hover: "#6673FF",
  hyperlink: "#6673FF",
  visited_hyperlink: "#e89e43",
  h1: "6rem",
  h2: "4rem",
  h3: "2rem",
};
