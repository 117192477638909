import React from "react";
import { Card, HobbyIcon, TextContainer, HobbyTitle } from "./HobbyElements";

const HobbyCard = ({ icon, title, description }) => {
  return (
    <Card>
      <HobbyIcon>{icon}</HobbyIcon>
      <TextContainer>
        <HobbyTitle>{title}</HobbyTitle>
      </TextContainer>
    </Card>
  );
};

export default HobbyCard;
