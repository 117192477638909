import React from "react";
import {
  CtaAboveTitle,
  CtaContainer,
  CtaImage,
  CtaImageWrapper,
  CtaTextContainer,
  CtaTitle,
  CtaWrapper,
} from "./CtaElements";
import { Button } from "../GlobalComponents";
import CtaSvg from "../../images/cta.svg";
import { Link } from "react-scroll";

const Cta = () => {
  return (
    <CtaContainer id="home">
      <CtaWrapper>
        <CtaTextContainer>
          <CtaAboveTitle>Hello! I'm Márcio</CtaAboveTitle>
          <CtaTitle>I'm passionate about tech and renewable energy</CtaTitle>
          <Link
            to="about"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            aria-label="about"
          >
            <Button aria-label="click to learn more"> Learn more</Button>
          </Link>
        </CtaTextContainer>
        <CtaImageWrapper>
          <CtaImage src={CtaSvg} alt="call to action" />
        </CtaImageWrapper>
      </CtaWrapper>
    </CtaContainer>
  );
};

export default Cta;
