import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {   
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: "Roboto", sans-serif;
    transition: all 0.25s ease-in-out;
  }

a {
  color: ${({ theme }) => theme.hyperlink};
}

a:visited {
  color: ${({ theme }) => theme.visited_hyperlink};
}
`;