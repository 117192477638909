import { GiMaterialsScience, GiDinosaurRex } from "react-icons/gi";
import { FaBook } from "react-icons/fa";
import { FaSolarPanel } from "react-icons/fa"
import { MdOutlineEvStation } from "react-icons/md"
import { BsTranslate } from "react-icons/bs"

const data = [
  {
    id: 5,
    title: "Renewable energy",
    icon: <FaSolarPanel />,
    description: "",
  },
  {
    id: 6,
    title: "Sustainable mobility",
    icon: <MdOutlineEvStation />,
    description: "",
  },
  {
    id: 3,
    title: "Science",
    icon: <GiMaterialsScience />,
    description: "",
  },
  {
    id: 1,
    title: "Writing",
    icon: <BsTranslate />,
    description: "",
  },
  {
    id: 2,
    title: "Reading",
    icon: <FaBook />,
    description: "",
  },
  {
    id: 4,
    title: "Dinosaurs",
    icon: <GiDinosaurRex />,
    description: "",
  },
];

export default data;
