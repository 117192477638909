import styled from "styled-components";

export const HobbyContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  margin: 10rem auto;
`;

export const HobbySectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.h2};
  margin-bottom: 4rem;
  text-align: center;
`;

export const HobbyGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4rem;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 2rem;
  width: 250px;
  border: 1px solid ${({ theme }) => theme.text};
  border-radius: 10px;

  //novo
  @media (min-width: 768px) {
    margin-bottom: 0rem;
  }
`;

export const HobbyIcon = styled.div`
  display: flex;
  font-size: 4rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.text};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HobbyTitle = styled.h3`
  font-size: clamp(1.5rem, 1.5vw, 1.7rem);
`;

export const HobbyText = styled.p`
  font-size: clamp(0.9rem, 1.2vw, 2rem);
`;
