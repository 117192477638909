import React from "react";
import { HobbyContainer, HobbyGrid, HobbySectionTitle } from "./HobbyElements";
import data from "./data";
import HobbyCard from "./HobbyCard";

const Hobby = () => {
  return (
    <HobbyContainer id="hobbies">
      <HobbySectionTitle>Knowledge & interests</HobbySectionTitle>
      <HobbyGrid>
        {data.map((e) => {
          return (
            <HobbyCard
              key={e.id}
              icon={e.icon}
              title={e.title}
              description={e.description}
            />
          );
        })}
      </HobbyGrid>
    </HobbyContainer>
  );
};

export default Hobby;
