import { GlobalStyles } from "./components/styling/globalStyling";
import { GlobalContainer } from "./components/GlobalComponents";
import Cta from "./components/CTA/Cta";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import Layout from "./components/LayoutNav/LayoutNav";
import Hobby from "./components/Hobbies/Hobby";

function App() {
  return (
    <Layout>
      <GlobalStyles />
      <GlobalContainer>
        <Cta />
        <About />
        <Hobby />
        <Footer />
      </GlobalContainer>
    </Layout>
  );
}

export default App;
