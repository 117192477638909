import React from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import { ToggleButton } from "./Toggle.elements";

const Toggle = ({ theme, toggleTheme }) => {
  const isLight = theme === "light";
  return (
    <ToggleButton
      aria-label="change between light and dark themes"
      onClick={toggleTheme}
    >
      {isLight ? <FaSun /> : <FaMoon />}
    </ToggleButton>
  );
};

export default Toggle;
