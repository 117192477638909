import styled from "styled-components";

export const ToggleButton = styled.button`
  background: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.text};
  outline: none;
  color: ${({ theme }) => theme.body};
  position: absolute;
  right: 4rem;
  top: 2rem;
  border-radius: 30%;
  padding: 5px 5px 0;
  font-size: 1rem;

  @media (min-width: 768px) {
    right: 0;
    top: 0.5rem;
    border-radius: 30% 0 0 30%;
    font-size: 1.5rem;
    cursor: pointer;
  } ;
`;
