import React from "react";
import {
  FooterContainer,
  FooterCopyright,
  FooterSocialIcons,
  Link,
  Email
} from "./FooterElements";
import { SiTwitter, SiGithub, SiLinkedin } from "react-icons/si";

const Footer = () => {
  return (
    <FooterContainer id="footer">
      <FooterSocialIcons>
        <Link
          href="https://twitter.com/marciof"
          aria-label="Twitter link"
          target="_blank"
          rel="noreferrer"
        >
          <SiTwitter />
        </Link>
        <Link
          href="https://github.com/marciojpflorindo/"
          aria-label="Github link"
          target="_blank"
          rel="noreferrer"
        >
          <SiGithub />
        </Link>
        <Link
          href="https://www.linkedin.com/in/marcioflorindo/"
          aria-label="Linkedin link"
          target="_blank"
          rel="noreferrer"
        >
          <SiLinkedin />
        </Link>
      </FooterSocialIcons>
      <FooterCopyright>
        © {new Date().getFullYear()} Márcio Florindo
      </FooterCopyright> 
      <Email>
        Contact me at hi@marcioflorindo.com
      </Email>    
    </FooterContainer>
  );
};

export default Footer;
