import styled from "styled-components";
import { ReactComponent as Svg } from "../../images/logo.svg";
import { Link } from "react-scroll";

export const Nav = styled.nav`
  display: flex;
  position: fixed;
  width: 100%;
  height: 90px;
  top: 0;
  padding: 1rem;
  transition: all 0.25s ease-in-out;
  background: ${({ theme }) => theme.body};

  @media (min-width: 768px) {
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
  }
`;
export const NavbarContainer = styled.div`
  display: flex;
`;

export const MobileIcon = styled.div`
  display: block;
  position: absolute;
  z-index: 2;
  top: 2rem;
  right: 1rem;
  font-size: 1.82rem;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 60px;
  width: 60px;

  @media (min-width: 768px) {
    position: relative;
    top: 0;
    right: 0;
  }
`;

export const LogoImgSvg = styled(Svg)`
  height: 60px;
  width: 100%;
  fill: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
`;

export const LogoImg = styled.img`
  height: 60px;
  width: 100%;
`;

export const NavbarElementsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const NavbarElements = styled.div`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin-top: 70px;
  width: 100%;
  max-width: 1100px;
  transition: all 0.25s ease-in-out;
  right: ${({ click }) => (click ? 0 : "-100%")};
  background: ${({ theme }) => theme.body};

  @media (min-width: 768px) {
    position: initial;
    margin-top: 0;
    width: initial;
    border-radius: initial;
    background: none;
  }
`;

export const NavbarElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 32px 0;
  height: 75vh;
  padding: 2rem 0 4rem 0;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: initial;
    height: 80px;
  }
`;

export const NavLinks = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.text};

  &.active {
    border-bottom: 4px solid ${({ theme }) => theme.hover};
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    margin-right: 2rem;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:last-child {
      margin-right: 2.5rem;
    }
  }
`;
