import styled from "styled-components";

export const GlobalContainer = styled.div`
  margin: 1rem;

  @media (min-width: 768px) {
    margin: 1rem 2rem;
  }
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.button};
  color: ${({ theme }) => theme.button_text};
  white-space: nowrap;
  border-radius: 4px;
  padding: 1rem 1.5rem;
  font-size: 1.3rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.25s ease-out;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 7px 13px -7px ${({ theme }) => theme.text};
  }
`;
