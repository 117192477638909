import styled from "styled-components";

export const AboutContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 1300px;
  margin: 10rem auto;
`;

export const AboutTitle = styled.h2`
  font-size: ${({ theme }) => theme.h2};
  margin-bottom: 3rem;
`;

export const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 1080px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const MyImg = styled.img`
  max-width: 46%;
  max-height: 100%;
  margin-bottom: 1.5rem;
  border-radius: 16px;

  @media (min-width: 1080px) {
    max-width: 23%;
    margin-right: 2rem;
    box-shadow: 0px 10px 13px -7px ${({ theme }) => theme.text};
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;

  @media (min-width: 1080px) {
    max-width: 50%;
    align-items: flex-start;
  }
`;

export const DescriptionTitle = styled.h3`
  font-size: ${({ theme }) => theme.h3};
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const DescriptionParagraph = styled.p`
  font-size: clamp(1rem, 1.2vw, 1.2rem);
  line-height: 1.8;

  @media (min-width: 768px) {
    text-align: left;
  }
`;
