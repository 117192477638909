import React from "react";
import {
  AboutContainer,
  AboutTitle,
  AboutWrapper,
  Description,
  DescriptionParagraph,
  DescriptionTitle,
  MyImg,
} from "./AboutElements";
import Me from "../../images/me.jpg";

const About = () => {
  return (
    <AboutContainer id="about">
      <AboutTitle>About me</AboutTitle>
      <AboutWrapper>
        <MyImg src={Me} alt="Me staring into I don't know what exatcly :)" />
        <Description>
          <DescriptionTitle>Hey. I'm Márcio</DescriptionTitle>
          <DescriptionParagraph>
            Hey there! I'm a tech writer who began his career as a tech journalist. Sustainability is one of my passions. I'm particularly interested in renewable energy, sustainable buildings and mobility, and ways of improving energy performance. <br />
            Right now, I'm helping <a href="https://www.cloudflare.com" aria-label="Cloudflare link" target="_blank" rel="noreferrer"> Cloudflare</a> to build a better Internet. I'm creating documentation for products such as <a href="https://developers.cloudflare.com/magic-wan/" aria-label="Magic WAN" target="_blank" rel="noreferrer">Magic WAN</a>, <a href="https://developers.cloudflare.com/magic-transit/" aria-label="Magic Transit" target="_blank" rel="noreferrer">Magic Transit</a>, or and <a href="https://developers.cloudflare.com/magic-cloud-networking/" aria-label="Magic Cloud Networking" target="_blank" rel="noreferrer">Magic Cloud Networking</a> among others.<br />
            Feel free to contact me. Links down below.
          </DescriptionParagraph>
        </Description>
      </AboutWrapper>
    </AboutContainer>
  );
};

export default About;
