import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  padding: 1rem 0;
`;

export const FooterDesign = styled.a`
  margin-top: 1.5rem;
  font-size: 0.8rem;
  /* font-size: clamp(1rem, 1.2vw, 2rem); */
  text-decoration: none;
  color: ${({ theme }) => theme.text};
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
`;

export const FooterSocialIcons = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  font-size: clamp(1.2rem, 1.7vw, 2.5rem);
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    width: 15%;
  }
`;

export const FooterCopyright = styled.p`
  margin: 0.4rem;
  font-size: clamp(1rem, 1.2vw, 2rem);
`;

export const Email = styled.p`
  margin: 1 rem;
  padding: 1rem 0;
  font-size: 0.8rem;
  /* font-style: italic; */
`;
